import { useTranslation } from 'react-i18next';
import React from 'react';
import { Modal } from 'antd';
import {
  FormGroup,
  Grid,
  Segment,
  RequestStatus,
} from '@mss/mss-component-library';
import dateFormat from 'dateformat';
import styled from 'styled-components';

const GridRow = styled(Grid.Row)<{ border?: boolean }>`
  border-bottom: ${p =>
    p.border === false ? 'none' : `1px solid ${p.theme.colors.common.grey200}`};
  margin: 0;
`;

const GridCol = styled(Grid.Col)`
  padding: 5px 16px;
`;

const RemoteRequestViewModal = ({ closeModal, request, visible }: any) => {
  const [t] = useTranslation(['remoteAccess', 'common', 'sites']);

  return (
    <Modal
      title={t('Remote request')}
      onOk={closeModal}
      okText={t('Close')}
      okButtonProps={{
        type: 'primary',
      }}
      onCancel={closeModal}
      cancelButtonProps={{ style: { display: 'none' } }}
      visible={visible}
      width={800}
    >
      {visible && request?.id && (
        <Grid.Container>
          <FormGroup
            label={''}
            input={
              <Segment border>
                <GridRow>
                  <GridCol width={4}>{t('ID')}</GridCol>
                  <GridCol width={8}>{request.id}</GridCol>
                </GridRow>
                <GridRow>
                  <GridCol width={4}>{t('common:Asset name')}</GridCol>
                  <GridCol width={8}>{request.asset_object.name}</GridCol>
                </GridRow>
                <GridRow>
                  <GridCol width={4}>{t('Profile')}</GridCol>
                  <GridCol width={8}>{request.profile}</GridCol>
                </GridRow>
                <GridRow>
                  <GridCol width={4}>{t('Start Time')}</GridCol>
                  <GridCol width={8}>
                    {request.approved_start_date &&
                      dateFormat(
                        request.approved_start_date || '',
                        'mmmm dS, yyyy @ h:MM:ss TT',
                      )}
                  </GridCol>
                </GridRow>
                <GridRow>
                  <GridCol width={4}>{t('End Time')}</GridCol>
                  <GridCol width={8}>
                    {request.approved_end_date &&
                      dateFormat(
                        request.approved_end_date || '',
                        'mmmm dS, yyyy @ h:MM:ss TT',
                      )}
                  </GridCol>
                </GridRow>
                <GridRow>
                  <GridCol width={4}>{t('Ticket / Call ID')}</GridCol>
                  <GridCol width={8}>{request.ticket_id}</GridCol>
                </GridRow>
                <GridRow>
                  <GridCol width={4}>{t('sites:Status')}</GridCol>
                  <GridCol width={8}>
                    <RequestStatus status={request.status} />
                  </GridCol>
                </GridRow>
                <GridRow border={false}>
                  <GridCol width={4}>{t('Message')}</GridCol>
                  <GridCol width={8}>{request.approval_message}</GridCol>
                </GridRow>
              </Segment>
            }
          />
        </Grid.Container>
      )}
    </Modal>
  );
};

export default RemoteRequestViewModal;
