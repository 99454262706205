import React, { useEffect, useMemo, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectedSite, selectedSites } from 'sites/store/selectors';
import emptySite from 'sites/util/emptySite';
import styled from 'styled-components';
import world from '../../sites/views/world-110m.json';
import { ApiSite } from '@mss/mss-component-library';

import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  Markers,
  MarkerType,
  ZoomableGroup,
} from 'react-simple-maps';

const Container = styled.div`
  display: flex;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

interface Site extends MarkerType, ApiSite {
  color: string;
}

const serializeSitesToMap = (sites: ApiSite[]): Site[] => {
  return sites.map(site => ({
    ...site,
    coordinates: [parseFloat(site.longitude), parseFloat(site.latitude)],
    color: site.health_status === 'RED' ? '#fa4654' : '#5eba00',
  }));
};

const MapChart = () => {
  const [markers, setMarkers] = useState<Site[] | null>(null);
  const [center, setCenter] = useState([0, 0]);
  const site = useSelector(selectedSite);
  useEffect(() => {
    setMarkers(serializeSitesToMap([site]));
    setCenter([parseFloat(site.latitude), parseFloat(site.longitude)]);
  }, [site]);

  return (
    <ComposableMap
      width={1024}
      height={700}
      style={{
        marginTop: '1rem',
      }}
    >
      <ZoomableGroup center={[center[0] - 47.5, center[1] + 47.5]} zoom={6}>
        <Geographies geography={world}>
          {(geographies, projection) =>
            geographies.map((geo, i: number) => (
              <Geography
                key={i}
                geography={geo}
                projection={projection}
                style={{
                  default: {
                    fill: '#ECEFF1',
                    stroke: '#607D8B',
                    strokeWidth: 0.75,
                    outline: 'none',
                  },
                  hover: {
                    fill: '#ECEFF1',
                    stroke: '#607D8B',
                    strokeWidth: 0.75,
                    outline: 'none',
                  },
                  pressed: {
                    fill: '#ECEFF1',
                    stroke: '#607D8B',
                    strokeWidth: 0.75,
                    outline: 'none',
                  },
                }}
              />
            ))
          }
        </Geographies>
        <Markers>
          {markers &&
            markers.map((marker, i) => (
              <Marker
                key={i}
                marker={marker}
                style={{
                  default: { fill: marker.color },
                  hover: { fill: '#FFFFFF' },
                  pressed: { fill: marker.color },
                }}
              >
                <circle
                  cx={0}
                  cy={0}
                  r={6}
                  fill={marker.color}
                  stroke={marker.color}
                />
                <text
                  textAnchor="middle"
                  y={-10}
                  style={{
                    fontFamily: 'Roboto, sans-serif',
                    fill: 'var(--gray-dark)',
                    fontSize: '0.7rem',
                    fontWeight: 800,
                  }}
                >
                  {marker.name}
                </text>
              </Marker>
            ))}
        </Markers>
      </ZoomableGroup>
    </ComposableMap>
  );
};

function Home() {
  const [t] = useTranslation('common');
  const { siteId } = useParams<{ siteId: string }>();
  const sites = useSelector(selectedSites);

  const selectedSite = useMemo(() => {
    const site = sites.find(x => x.id === siteId);
    if (!site) return emptySite;

    return site;
  }, [sites, siteId]);

  return (
    <Container>
      <h1 className="h3 mb-4 mt-4">
        {t('Site name')}:{' '}
        <span className="text-muted">{selectedSite.name}</span>
      </h1>
      <MapChart></MapChart>
    </Container>
  );
}

export default withRouter(Home);
