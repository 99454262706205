import {
  AlertTriangle,
  BarChart, // ExternalLink,
  File,
  FileText,
  HardDrive,
  Home,
  Box,
  Sliders,
  Compass,
} from 'react-feather';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  NavItem,
  UncontrolledDropdown,
} from 'reactstrap';
import {
  NavLink,
  matchPath,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import React, { useMemo } from 'react';

import Divider from 'common/components/Divider';
import {
  PermissionGuard,
  MssIcon,
  IconStartConnection,
} from '@mss/mss-component-library';
import { selectMe } from 'settings/store/selectors';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectedDashboards } from 'dashboards/store/selectors';
import { selectedSite } from 'sites/store/selectors';

const SiteNavbar = () => {
  const [t] = useTranslation(['common', 'sites', 'controlApps']);
  const location = useLocation();
  const { url } = useRouteMatch();
  const user: any = useSelector(selectMe);
  const dashboards = useSelector(selectedDashboards);
  const site = useSelector(selectedSite);

  const isAppURL =
    matchPath(location.pathname, {
      path: '/site/:siteId/applications',
    }) !== null;

  const isAssetURL =
    matchPath(location.pathname, {
      path: '/site/:siteId/assets',
    }) !== null;

  const isDashboardURL =
    matchPath(location.pathname, {
      path: '/site/:siteId/dashboard',
    }) !== null;

  const dashboardItems = useMemo(
    () =>
      dashboards
        .filter(x => x.favorite)
        .map(dashboard => (
          <DropdownItem
            tag={NavLink}
            to={`${url}/dashboard/${dashboard.slug}`}
            data-ta={`dashboard-menu-${dashboard.slug}`}
            key={dashboard.id}
          >
            {t(dashboard.name.trim())}
          </DropdownItem>
        )),
    [dashboards, t, url],
  );
  return (
    <>
      <NavItem>
        <NavLink
          exact
          className="nav-link"
          to={`${'/sites'}`}
          data-ta="HomeMenu"
        >
          <Home className="nav-icon" size={14} />
          {t('Home')}
        </NavLink>
      </NavItem>
      <Divider vertical />
      <NavItem>
        <NavLink
          exact
          className="nav-link"
          to={`${url}/home`}
          data-ta="SiteHomeMenu"
        >
          <Compass className="nav-icon" size={14} />
          {t('Site Home')}
        </NavLink>
      </NavItem>
      <PermissionGuard any codename={['view_center_dashboard']}>
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle
            nav
            className={`d-flex align-items-center ${
              isDashboardURL ? 'active' : ''
            }`}
            data-ta="DashboardsMenu"
          >
            <BarChart className="nav-icon" size={14} />
            {t('Dashboard')}
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow">
            <DropdownItem
              tag={NavLink}
              to={`${url}/dashboard`}
              exact
              data-ta="DashboardOverviewMenu"
            >
              {t('Overview')}
            </DropdownItem>
            {dashboardItems.length > 0 && <Divider />}
            {dashboardItems}
          </DropdownMenu>
        </UncontrolledDropdown>
      </PermissionGuard>
      <PermissionGuard
        any
        codename={[
          'view_center_securityapplicationwsus',
          'view_center_securityapplicationmcafeeepo',
          'view_center_assetmanagementapplicationprm',
          'view_center_assetmanagementapplicationisae',
          'view_center_controlapplicationcentumvp',
          'view_center_analyzermanagementapplicationaaims',
          'view_center_analyzermanagementapplicationcalsys',
          'view_center_securityapplicationclaroty',
          'view_center_securityapplicationnozomi',
          'view_center_securityapplicationveeam',
        ]}
      >
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle
            nav
            className={`d-flex align-items-center ${isAppURL ? 'active' : ''}`}
            data-ta="ApplicationsMenu"
          >
            <Box className="nav-icon" size={14} />
            {t('Applications')}
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow">
            <PermissionGuard
              any
              codename={[
                'view_center_securityapplicationwsus',
                'view_center_securityapplicationmcafeeepo',
                'view_center_securityapplicationclaroty',
                'view_center_securityapplicationnozomi',
                'view_center_securityapplicationveeam',
              ]}
            >
              <DropdownItem
                tag={NavLink}
                to={`${url}/applications/security`}
                data-ta="SecurityApplicationsMenu"
              >
                {t('Security applications')}
              </DropdownItem>
            </PermissionGuard>
            <PermissionGuard
              any
              codename={[
                'view_center_assetmanagementapplicationprm',
                'view_center_assetmanagementapplicationisae',
              ]}
            >
              <DropdownItem
                tag={NavLink}
                to={`${url}/applications/asset_management`}
                data-ta="AssetManagementApplicationsMenu"
              >
                {t('Asset management applications')}
              </DropdownItem>
            </PermissionGuard>
            <PermissionGuard
              any
              codename={['view_center_controlapplicationcentumvp']}
            >
              <DropdownItem
                tag={NavLink}
                to={`${url}/applications/control`}
                data-ta="ControlApplicationsMenu"
              >
                {t('controlApps:Control applications')}
              </DropdownItem>
            </PermissionGuard>
            <PermissionGuard
              any
              codename={[
                'view_center_analyzermanagementapplicationaaims',
                'view_center_analyzermanagementapplicationcalsys',
              ]}
            >
              <DropdownItem
                tag={NavLink}
                to={`${url}/applications/analyzer_management`}
                data-ta="AnalyzerManagementApplicationsMenu"
              >
                {t('Analyzer management applications')}
              </DropdownItem>
            </PermissionGuard>
          </DropdownMenu>
        </UncontrolledDropdown>
      </PermissionGuard>
      <PermissionGuard
        any
        codename={[
          'view_center_computeassetwindowsagent',
          'view_center_computeassetwindowswmi',
          'view_center_fieldassetprm',
          'view_center_dcsplcassetfcs',
          'view_center_dcsplcassetscs',
          'view_center_dcsplcassetavr',
          'view_center_dcsplcassetbcv',
          'view_center_dcsplcassetwac',
          'view_center_dcsplcassetfcn',
          'view_center_networkassetswitch',
          'view_center_networkassetrouter',
          'view_center_networkassetfirewall',
          'view_center_networkassettimeserver',
          'view_center_envassetodu',
        ]}
      >
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle
            nav
            className={`d-flex align-items-center ${
              isAssetURL ? 'active' : ''
            }`}
            data-ta="AssetsMenu"
          >
            <HardDrive className="nav-icon" size={14} />
            {t('Assets')}
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow">
            <PermissionGuard
              any
              codename={[
                'view_center_computeassetwindowsagent',
                'view_center_computeassetwindowswmi',
              ]}
            >
              <DropdownItem
                tag={NavLink}
                to={`${url}/assets/compute`}
                data-ta="ComputeAssetsMenu"
              >
                {t('Compute assets')}
              </DropdownItem>
            </PermissionGuard>
            <PermissionGuard
              any
              codename={[
                'view_center_dcsplcassetfcs',
                'view_center_dcsplcassetscs',
                'view_center_dcsplcassetavr',
                'view_center_dcsplcassetbcv',
                'view_center_dcsplcassetwac',
                'view_center_dcsplcassetfcn',
              ]}
            >
              <DropdownItem
                tag={NavLink}
                to={`${url}/assets/plc-dcs`}
                data-ta="PLCDCSAssetsMenu"
              >
                {t('PLC/DCS assets')}
              </DropdownItem>
            </PermissionGuard>
            <PermissionGuard any codename={['view_center_fieldassetprm']}>
              <DropdownItem
                tag={NavLink}
                to={`${url}/assets/field`}
                data-ta="FieldAssetsMenu"
              >
                {t('Field assets')}
              </DropdownItem>
            </PermissionGuard>
            <PermissionGuard
              any
              codename={[
                'view_center_networkassetswitch',
                'view_center_networkassetrouter',
                'view_center_networkassetfirewall',
                'view_center_networkassettimeserver',
              ]}
            >
              <DropdownItem
                tag={NavLink}
                to={`${url}/assets/network`}
                data-ta="NetworkAssetsMenu"
              >
                {t('Network assets')}
              </DropdownItem>
            </PermissionGuard>
            <PermissionGuard any codename={['view_center_envassetodu']}>
              <DropdownItem
                tag={NavLink}
                to={`${url}/assets/env`}
                data-ta="EnvAssetsMenu"
              >
                {t('Environmental assets')}
              </DropdownItem>
            </PermissionGuard>
          </DropdownMenu>
        </UncontrolledDropdown>
      </PermissionGuard>
      <PermissionGuard any codename={['view_center_alert', 'change_alert']}>
        <NavItem>
          <NavLink
            exact
            className="nav-link"
            to={`${url}/alerts`}
            data-ta="AlertsMenu"
          >
            <AlertTriangle size={14} className={'mr-1'} />
            {t('Alerts')}
          </NavLink>
        </NavItem>
      </PermissionGuard>

      <PermissionGuard
        any
        codename={['view_center_apr', 'view_all_center_apr']}
      >
        <NavItem>
          <NavLink
            className="nav-link"
            to={`${url}/reports`}
            data-ta="AprReports"
          >
            <FileText size={14} className={'mr-1'} />
            {t('APR Reports')}
          </NavLink>
        </NavItem>
      </PermissionGuard>

      <PermissionGuard
        any
        codename={[
          'start_rdp_remotesession_center',
          'start_ssh_remotesession_center',
          'start_vnc_remotesession_center',
          'start_web_remotesession_center',
          'create_rdp_remoterequest_center',
          'create_ssh_remoterequest_center',
          'create_vnc_remoterequest_center',
          'create_web_remoterequest_center',
        ]}
      >
        <NavItem>
          <NavLink
            exact
            className="nav-link"
            to={`${url}/remote-access`}
            data-ta="RemoteAccessMenu"
          >
            <MssIcon
              icon={IconStartConnection}
              size={14}
              className="nav-icon mr-1"
            />
            {t('Remote access')}
          </NavLink>
        </NavItem>
      </PermissionGuard>
      <PermissionGuard
        any
        codename={[
          'add_center_filetransfer',
          'view_center_filetransfer',
          'share_filetransfer',
          'expire_filetransfer',
        ]}
      >
        <NavItem>
          <NavLink
            exact
            className="nav-link"
            to={`${url}/file-transfer`}
            data-ta="FileTransferMenu"
          >
            <File size={14} className="nav-icon mr-1" />
            {t('File Transfer')}
          </NavLink>
        </NavItem>
      </PermissionGuard>
      {user.is_superuser && (
        <>
          <Divider vertical />
          <NavItem>
            <NavLink
              className="nav-link"
              to={`/sites/manager/${site.id}`}
              data-ta="SiteManagementMenu"
            >
              <Sliders className="nav-icon" size={14} />
              {t('Site Management')}
            </NavLink>
          </NavItem>
        </>
      )}
    </>
  );
};

export default SiteNavbar;
