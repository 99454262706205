import cn from 'classnames';
import Loading from 'common/components/Loading';
import useFetchElasticQuery from 'common/hooks/useFetchElasticQuery';
import get from 'lodash/get';
import moment from 'moment';
import { ComputeAssetEvent } from '@mss/mss-component-library';
import React, { useState } from 'react';
import { FileText, Search } from 'react-feather';
import {
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import assetIndex from 'common/utils/queryIndexBuilder';
import { useTranslation } from 'react-i18next';

interface MaintenanceEventsModalProps {
  id: string;
  event_id: string;
  site_id: string;
  event: ComputeAssetEvent;
  isAgentBased: boolean;
  closeModal: () => void;
  fetcher: () => void;
  fetcherString: string;
  hasDetails: boolean;
}

function GeneralAndDetailsModal({
  id,
  event_id,
  site_id,
  closeModal,
  fetcher,
  fetcherString,
  hasDetails,
}: MaintenanceEventsModalProps) {
  const [t] = useTranslation(['assetsCompute', 'assetManagement']);
  const [activeTab, setActiveTab] = useState<number>(1);

  const index = assetIndex({
    mainType: 'applications',
    type: 'prm',
    siteId: site_id,
    endpoint: 'logs',
  });

  const [loadingDetail, detailData] = useFetchElasticQuery(
    fetcher,
    fetcherString,
    {
      id,
      event_id,
      index,
    },
    { refetchInterval: false },
  );

  if (loadingDetail) return <Loading />;

  const detailSource = get(detailData, 'hits.hits[0]._source');

  return (
    <Modal isOpen toggle={closeModal} size="lg">
      <ModalHeader toggle={closeModal} cssModule={{ 'modal-title': 'w-100' }}>
        <div>{`${t('Event')}: ${event_id}`}</div>
      </ModalHeader>
      <ModalBody>
        <div className="border">
          <Nav tabs className="mb-4">
            <NavItem>
              <NavLink
                className={cn(
                  { active: activeTab === 1 },
                  'd-flex justify-content-between align-items-center',
                )}
                onClick={() => {
                  setActiveTab(1);
                }}
              >
                <FileText size={14} className="mr-1" />
                {t('General')}
              </NavLink>
            </NavItem>
            {hasDetails && (
              <NavItem>
                <NavLink
                  className={cn(
                    { active: activeTab === 2 },
                    'd-flex justify-content-between align-items-center',
                  )}
                  onClick={() => {
                    setActiveTab(2);
                  }}
                >
                  <Search size={14} className={'mr-1'} />
                  {t('Details')}
                </NavLink>
              </NavItem>
            )}
          </Nav>
          <TabContent activeTab={activeTab} className="m-4">
            <TabPane tabId={1}>
              <div>
                <div className="form-group row mt-4">
                  <label
                    htmlFor="event_type"
                    className="col-sm-2 col-form-label font-weight-bold"
                  >
                    {`${t('Event Type')}:`}
                  </label>
                  <div className="col-sm-4">
                    <input
                      type="text"
                      readOnly
                      className="form-control-plaintext"
                      id="event_type"
                      value={get(detailSource, 'event_type')}
                    />
                  </div>
                  <label
                    htmlFor="event_group"
                    className="col-sm-2 col-form-label font-weight-bold"
                  >
                    {t('Event Group')}
                  </label>
                  <div className="col-sm-4">
                    <input
                      type="text"
                      readOnly
                      className="form-control-plaintext"
                      id="event_group"
                      value={get(detailSource, 'event_group')}
                    />
                  </div>
                </div>

                <div className="form-group row mt-4">
                  <label
                    htmlFor="event_id"
                    className="col-sm-2 col-form-label font-weight-bold"
                  >
                    {t('Event ID')}
                  </label>
                  <div className="col-sm-4">
                    <input
                      type="text"
                      readOnly
                      className="form-control-plaintext"
                      id="event_id"
                      value={event_id}
                    />
                  </div>
                  <label
                    htmlFor="timestamp"
                    className="col-sm-2 col-form-label font-weight-bold"
                  >
                    {t('Logged')}
                  </label>
                  <div className="col-sm-4">
                    <input
                      type="text"
                      readOnly
                      className="form-control-plaintext"
                      id="timestamp"
                      value={moment(get(detailSource, 'timestamp')).format(
                        'LLLL',
                      )}
                    />
                  </div>
                </div>

                <div className="form-group row mt-4">
                  <label
                    htmlFor="user_id"
                    className="col-sm-2 col-form-label font-weight-bold"
                  >
                    {`${t('User ID')}:`}
                  </label>
                  <div className="col-sm-4">
                    <input
                      type="text"
                      readOnly
                      className="form-control-plaintext"
                      id="user_id"
                      value={get(detailSource, 'user_id') || ''}
                    />
                  </div>
                  <label
                    htmlFor="system"
                    className="col-sm-2 col-form-label font-weight-bold"
                  >
                    {t('System')}
                  </label>
                  <div className="col-sm-4">
                    <input
                      type="text"
                      readOnly
                      className="form-control-plaintext"
                      id="system"
                      value={get(detailSource, 'system') || ''}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group row mt-4">
                <label
                  htmlFor="device_tag"
                  className="col-sm-2 col-form-label font-weight-bold"
                >
                  {`${t('Device Tag')}:`}
                </label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    readOnly
                    className="form-control-plaintext"
                    id="device_tag"
                    value={get(detailSource, 'device_tag')}
                  />
                </div>
              </div>

              <div className="form-group row mt-4">
                <label
                  htmlFor="event_message"
                  className="col-sm-2 col-form-label font-weight-bold"
                >
                  {`${t('Event Message')}:`}
                </label>
                <Input
                  type="textarea"
                  name="message"
                  disabled
                  value={get(detailSource, 'message')}
                  className="text-black"
                  rows="5"
                />
              </div>
              <div className="form-group row mt-4">
                <label
                  htmlFor="reason"
                  className="col-sm-2 col-form-label font-weight-bold"
                >
                  {`${t('Reason')}:`}
                </label>
                <Input
                  type="textarea"
                  name="reason"
                  disabled
                  value={get(detailSource, 'reason')}
                  className="text-black"
                  rows="5"
                />
              </div>
            </TabPane>
            {hasDetails && (
              <TabPane tabId={2}>
                <Input
                  type="textarea"
                  name="maint_alarm_details"
                  disabled
                  value={get(detailSource, 'maint_alarm_details')}
                  className="text-black"
                  rows="10"
                />
              </TabPane>
            )}
          </TabContent>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default GeneralAndDetailsModal;
