import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectedReport } from 'reports/store/selectors';
import { getReportStateTitle } from 'reports/utils/reportUtils';
import { ReportEntityAPI } from 'reports/views/List';

function ReportsOverviewBreadcrumb() {
  const currentReport: ReportEntityAPI = useSelector(selectedReport);
  const [t] = useTranslation('report');

  const startDate = moment(currentReport?.start_date).format('MMMM D, YYYY');
  const endDate = moment(currentReport?.end_date).format('MMMM D, YYYY');

  // Show state only on reports in progress
  const reportState: string = currentReport.published_at
    ? ''
    : `(${getReportStateTitle(currentReport.state, t)})`;

  return (
    `${currentReport?.asset_name} - ${startDate} till ${endDate} ${reportState} ` ||
    null
  );
}

export default ReportsOverviewBreadcrumb;
