import Loading from 'common/components/Loading';
import { Switch } from '@mss/mss-component-library';
import useFetchElasticQuery from 'common/hooks/useFetchElasticQuery';
import { objectDifference } from 'common/utils';
import ChangedEvent from 'computeAssets/components/ChangedEvent';
import { fetchComputeAssetsProgramEvents_ab } from 'computeAssets/services/Agentbased/FetchComputeAssetsProgramEvents';
import { fetchComputeAssetsProgramEvents_al } from 'computeAssets/services/Agentless/FetchComputeAssetsProgramEvents';
import get from 'lodash/get';
import moment from 'moment';
import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader, Table } from 'reactstrap';
import assetIndex from 'common/utils/queryIndexBuilder';
import { useSelector } from 'react-redux';
import { selectedSite } from 'sites/store/selectors';
import { useTranslation } from 'react-i18next';

interface ProgramEventDifference {
  name?: string;
  publisher?: string;
  install_date?: string;
  version?: string;
}

function ProgramEventsModal({ id, name, isAgentBased, closeModal }) {
  const [t] = useTranslation('assetsCompute');
  const { id: siteId } = useSelector(selectedSite);
  const index = assetIndex({
    siteId,
    endpoint: 'catalogue',
    type: 'compute',
    subType: isAgentBased ? 'ab' : 'al',
  });

  const [showChanges, setShowChanges] = useState(false);
  const [loading, data] = useFetchElasticQuery(
    isAgentBased
      ? fetchComputeAssetsProgramEvents_ab
      : fetchComputeAssetsProgramEvents_al,
    isAgentBased
      ? 'fetchComputeAssetsProgramEvents_ab'
      : 'fetchComputeAssetsProgramEvents_al',
    {
      id,
      name,
      index,
    },
  );

  if (loading) return <Loading />;

  const hits = get(data, 'aggregations.top_uids_hits.hits.hits', []);

  const rows = hits.map((hit, index) => {
    const columns = get(hit, '_source.json.columns');

    let difference: ProgramEventDifference = {};

    if (index !== hits.length - 1) {
      difference = objectDifference(
        columns,
        get(hits[index + 1], '_source.json.columns'),
      );
    }
    const calendarTime = hit._source['@timestamp'];

    return (
      <tr key={hit.key}>
        <td>{moment(calendarTime).format('MMMM Do YYYY, h:mm:ss a')}</td>
        <td>
          <ChangedEvent changed={showChanges && !!difference.name}>
            {columns.name}
          </ChangedEvent>
        </td>
        <td>
          <ChangedEvent changed={showChanges && !!difference.publisher}>
            {columns.publisher}
          </ChangedEvent>
        </td>
        <td>
          <ChangedEvent changed={showChanges && !!difference.install_date}>
            {columns.install_date}
          </ChangedEvent>
        </td>
        <td>
          <ChangedEvent changed={showChanges && !!difference.version}>
            {columns.version}
          </ChangedEvent>
        </td>
      </tr>
    );
  });

  return (
    <Modal isOpen toggle={closeModal} size="lg">
      <ModalHeader toggle={closeModal} cssModule={{ 'modal-title': 'w-100' }}>
        <div className="d-flex justify-content-between w-100">
          <div> {`${t('Program')}: ${name}`}</div>
          <div>
            <label className="mr-2 text-muted text-sm">
              {t('Show changes')}
            </label>
            <Switch
              size="small"
              onChange={() => setShowChanges(!showChanges)}
              isChecked={showChanges}
            />
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <Table responsive className="table-vcenter">
          <thead>
            <tr>
              <th>{t('Timestamp')}</th>
              <th>{t('Name')}</th>
              <th>{t('Publisher')}</th>
              <th>{t('Install date')}</th>
              <th>{t('Version')}</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </ModalBody>
    </Modal>
  );
}

export default ProgramEventsModal;
