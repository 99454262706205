import { createSelector } from 'reselect';
import { ComputeAsset } from '@mss/mss-component-library';

export const selectedComputeAssets = state =>
  state.default.computeAssets.computeAssets;

export const selectedComputeAsset = state =>
  state.default.computeAssets.computeAsset;

export const selectComputeAssets = createSelector(
  [selectedComputeAssets],
  (computeAssets: ComputeAsset[]) => computeAssets,
);

export const selectComputeAsset = createSelector(
  [selectedComputeAsset],
  (computeAsset: ComputeAsset) => computeAsset,
);
