import Api from '../../common/services/Api';
import { fetchElasticQuery } from '../../common/services/FetchElasticQuery';
import { parseDateRange } from '../../common/utils';
import { includeFilters } from '../../common/utils/elasticHelpers';

export async function fetchAaimsActivityLogs({
  assetId,
  index,
  start,
  end,
  sort,
  pagination,
  filter,
  search,
}) {
  const { startMoment, endMoment } = parseDateRange(start, end);

  const dateRange = {
    range: {
      created_date: {
        gte: `${startMoment.format()}`,
        lte: `${endMoment.format()}`,
      },
    },
  };

  const query = {
    ...pagination,
    sort,
    _source: {
      includes: [
        'created_date',
        'event_type',
        'event_group',
        'user_name',
        'event_message',
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'activity_log',
            },
          },
          ...(filter ? includeFilters(filter) : []),
        ],
        filter: [
          {
            range: {
              created_date: {
                gte: `${startMoment.format()}`,
                lte: `${endMoment.format()}`,
              },
            },
          },
        ],
      },
    },
  };

  function filterPrefix(key: string, value: string) {
    return {
      prefix: {
        [key]: value,
      },
    };
  }

  if (search && search.length > 0) {
    query.query.bool.filter = {
      bool: {
        must: [dateRange],
      },
    };

    const validSearches = search
      .filter(x => x.value != null && x.value.length > 0)
      .map(x => filterPrefix(x.key, x.value));

    validSearches.forEach(x => query.query.bool.filter.bool.must.push(x));
  }

  return fetchElasticQuery(index, query);
}

export async function fetchAaimsActivityLogsCategories({
  assetId,
  index,
  start,
  end,
}) {
  const { startMoment, endMoment } = parseDateRange(start, end);
  const query = {
    aggs: {
      event_type: {
        terms: {
          field: 'event_type.keyword',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
      event_group: {
        terms: {
          field: 'event_group.keyword',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
      user_name: {
        terms: {
          field: 'user_name.keyword',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
    },
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'activity_log',
            },
          },
        ],
        filter: [
          {
            range: {
              created_date: {
                gte: `${startMoment.format()}`,
                lte: `${endMoment.format()}`,
              },
            },
          },
        ],
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchAaimsAnalyzers({
  assetId,
  index,
  pagination,
  sort,
  filter,
}) {
  const query = {
    ...pagination,
    sort,
    _source: {
      includes: [
        'name',
        'manufacturer',
        'type',
        'model',
        'service',
        'date_of_service',
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'connected_analyzers',
            },
          },
          ...(filter ? includeFilters(filter) : []),
        ],
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchAaimsAnalyzersCategories({ assetId, index }) {
  const query = {
    aggs: {
      type: {
        terms: {
          field: 'type.keyword',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
      name: {
        terms: {
          field: 'name.keyword',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
      manufacturer: {
        terms: {
          field: 'manufacturer.keyword',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
      model: {
        terms: {
          field: 'model.keyword',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
      service: {
        terms: {
          field: 'service.keyword',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
      date_of_service: {
        terms: {
          field: 'date_of_service.keyword',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
    },
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'connected_analyzers',
            },
          },
        ],
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchAaimsApplicationLogs({
  assetId,
  index,
  start,
  end,
  sort,
  pagination,
  filter,
  search,
}) {
  const { startMoment, endMoment } = parseDateRange(start, end);

  const dateRange = {
    range: {
      created_time: {
        gte: `${startMoment.format()}`,
        lte: `${endMoment.format()}`,
      },
    },
  };

  const query = {
    ...pagination,
    sort,
    _source: {
      includes: [
        'id',
        'created_time',
        'event_type',
        'analyser_name',
        'comments',
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'application_log',
            },
          },
          ...(filter ? includeFilters(filter) : []),
        ],
        filter: [
          {
            range: {
              created_time: {
                gte: `${startMoment.format()}`,
                lte: `${endMoment.format()}`,
              },
            },
          },
        ],
      },
    },
  };

  function filterPrefix(key: string, value: string) {
    return {
      prefix: {
        [key]: value,
      },
    };
  }

  if (search && search.length > 0) {
    query.query.bool.filter = {
      bool: {
        must: [dateRange],
      },
    };

    const validSearches = search
      .filter(x => x.value != null && x.value.length > 0)
      .map(x => filterPrefix(x.key, x.value));

    validSearches.forEach(x => query.query.bool.filter.bool.must.push(x));
  }

  return fetchElasticQuery(index, query);
}

export async function fetchAaimsApplicationLogsCategories({
  assetId,
  index,
  start,
  end,
}) {
  const { startMoment, endMoment } = parseDateRange(start, end);
  const query = {
    aggs: {
      id: {
        terms: {
          field: 'id.keyword',
          size: 10000,
          order: {
            _key: 'asc',
          },
        },
      },
      analyser_name: {
        terms: {
          field: 'analyser_name.keyword',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
      event_type: {
        terms: {
          field: 'event_type.keyword',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
    },
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'application_log',
            },
          },
        ],
        filter: [
          {
            range: {
              created_time: {
                gte: `${startMoment.format()}`,
                lte: `${endMoment.format()}`,
              },
            },
          },
        ],
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchAaimsApplicationUsers({
  assetId,
  index,
  pagination,
}) {
  const query = {
    ...pagination,
    _source: {
      includes: ['user_id', 'role_name', 'user_name'],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'application_users',
            },
          },
        ],
        filter: [],
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchAaimsMaintenanceEvents({
  assetId,
  index,
  start,
  end,
  sort,
  pagination,
  filter,
}) {
  const { startMoment, endMoment } = parseDateRange(start, end);
  const query = {
    ...pagination,
    sort,
    _source: {
      includes: [
        'id',
        'created_time',
        'analyser_name',
        'description',
        'alarm_state',
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'maintenance_events',
            },
          },
          ...(filter ? includeFilters(filter) : []),
        ],
        filter: [
          {
            range: {
              created_time: {
                gte: `${startMoment.format()}`,
                lte: `${endMoment.format()}`,
              },
            },
          },
        ],
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchAaimsMaintenanceEventsCategories({
  assetId,
  index,
  start,
  end,
}) {
  const { startMoment, endMoment } = parseDateRange(start, end);

  const query = {
    aggs: {
      id: {
        terms: {
          field: 'id.keyword',
          size: 10000,
          order: {
            _key: 'asc',
          },
        },
      },
      analyser_name: {
        terms: {
          field: 'analyser_name.keyword',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
      alarm_state: {
        terms: {
          field: 'alarm_state',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
    },
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'maintenance_events',
            },
          },
        ],
        filter: [
          {
            range: {
              created_time: {
                gte: `${startMoment.format()}`,
                lte: `${endMoment.format()}`,
              },
            },
          },
        ],
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchAaimsPerformance({ assetId, index }) {
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'performance',
            },
          },
        ],
        filter: [
          {
            range: {
              '@timestamp': {
                gte: 'now-49h',
                lte: 'now',
              },
            },
          },
        ],
      },
    },
    aggs: {
      analyser: {
        terms: {
          field: 'analyser_id',
        },
        aggs: {
          top_uids_hits: {
            top_hits: {
              sort: [
                {
                  '@timestamp': {
                    order: 'desc',
                  },
                },
              ],
              _source: {
                includes: [
                  'analyser_name',
                  'analyser_type',
                  'validation_type',
                  'availability_rate',
                  'breakdown_rate',
                  'checking_rate',
                  'reproducibility_rate',
                ],
              },
              size: 1,
            },
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export const fetchAaimsPerformanceCategories = async ({ assetId, index }) => {
  const query = {
    aggs: {
      analyser_name: {
        terms: {
          field: 'analyser_name.keyword',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
      analyser_type: {
        terms: {
          field: 'analyser_type.keyword',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
      validation_type: {
        terms: {
          field: 'validation_type.keyword',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
    },
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'performance',
            },
          },
        ],
        filter: [
          {
            range: {
              '@timestamp': {
                gte: 'now-49h',
                lte: 'now',
              },
            },
          },
        ],
      },
    },
  };

  return fetchElasticQuery(index, query);
};

export async function fetchAaimsValidationLogs({
  assetId,
  index,
  start,
  end,
  sort,
  pagination,
  filter,
}) {
  const { startMoment, endMoment } = parseDateRange(start, end);
  const query = {
    ...pagination,
    sort,
    _source: {
      includes: [
        'id',
        'end_time',
        'result',
        'analyser_name',
        'system_comments',
        'user_comments',
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'validation_events',
            },
          },
          ...(filter ? includeFilters(filter) : []),
        ],
        filter: [
          {
            range: {
              end_time: {
                gte: `${startMoment.format()}`,
                lte: `${endMoment.format()}`,
              },
            },
          },
        ],
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchAaimsValidationEventsCategories({
  assetId,
  index,
  start,
  end,
}) {
  const { startMoment, endMoment } = parseDateRange(start, end);

  const query = {
    aggs: {
      id: {
        terms: {
          field: 'id.keyword',
          size: 10000,
          order: {
            _key: 'asc',
          },
        },
      },
      analyzer_name: {
        terms: {
          field: 'analyzer_name.keyword',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
      result: {
        terms: {
          field: 'result',
          size: 100,
          order: {
            _key: 'asc',
          },
        },
      },
    },
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'validation_events',
            },
          },
        ],
        filter: [
          {
            range: {
              end_time: {
                gte: `${startMoment.format()}`,
                lte: `${endMoment.format()}`,
              },
            },
          },
        ],
      },
    },
  };

  return fetchElasticQuery(index, query);
}

export async function fetchAaimsHeartbeatChartData({ assetId, index }) {
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            range: {
              '@timestamp': {
                gte: 'now-30d',
                lte: 'now',
              },
            },
          },
        ],
      },
    },
    aggs: {
      state_over_time: {
        date_histogram: {
          field: '@timestamp',
          calendar_interval: '1d',
        },
        aggs: {
          state_aggregation: {
            terms: {
              field: 'state.keyword',
            },
          },
          up_percentage: {
            bucket_script: {
              buckets_path: {
                up: "state_aggregation['up']>_count",
                down: "state_aggregation['down']>_count",
              },
              gap_policy: 'insert_zeros',
              script: {
                source:
                  'if (params.down == null && params.up == null) {-1} else if (params.down == null) {1} else if (params.up == null) {0} else {params.up / (params.up + params.down)}',
              },
            },
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchCalsysAnalyzers({
  assetId,
  index,
  pagination,
  sort,
  filter,
}) {
  const query = {
    ...pagination,
    sort,
    _source: {
      includes: [
        'name',
        'vendor_and_model',
        'type',
        'category',
        'location',
        'criticality',
        'service',
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'analyzer_inventory',
            },
          },
          ...(filter ? includeFilters(filter) : []),
        ],
        filter: [
          {
            range: {
              '@timestamp': {
                gte: 'now-49h',
                lte: 'now',
              },
            },
          },
        ],
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchCalsysAnalyzersCategories({ assetId, index }) {
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'analyzer_inventory',
            },
          },
        ],
        filter: [
          {
            range: {
              '@timestamp': {
                gte: 'now-49h',
                lte: 'now',
              },
            },
          },
        ],
      },
    },
    aggs: {
      vendor_and_model: {
        terms: {
          field: 'vendor_and_model.keyword',
          size: 100,
          order: {
            _count: 'desc',
          },
        },
      },
      type: {
        terms: {
          field: 'type.keyword',
          size: 100,
          order: {
            _count: 'desc',
          },
        },
      },
      category: {
        terms: {
          field: 'category.keyword',
          size: 100,
          order: {
            _count: 'desc',
          },
        },
      },
      location: {
        terms: {
          field: 'location.keyword',
          size: 100,
          order: {
            _count: 'desc',
          },
        },
      },
      criticality: {
        terms: {
          field: 'criticality.keyword',
          size: 100,
          order: {
            _count: 'desc',
          },
        },
      },
      service: {
        terms: {
          field: 'service.keyword',
          size: 100,
          order: {
            _count: 'desc',
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchCalsysPerformance({ assetId, index }) {
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id.keyword': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'performance',
            },
          },
        ],
        filter: [
          {
            range: {
              '@timestamp': {
                gte: 'now-5d',
                lte: 'now',
              },
            },
          },
        ],
      },
    },
    aggs: {
      disk_size: {
        terms: {
          field: 'name.keyword',
          size: 10000,
        },
        aggs: {
          last_result_hits: {
            terms: {
              field: 'metric_date',
              order: {
                max_date: 'desc',
              },
              size: 1,
            },
            aggs: {
              max_date: {
                max: {
                  field: 'metric_date',
                },
              },
              utility: {
                max: {
                  field: 'util',
                },
              },
              breakdown: {
                max: {
                  field: 'breakdown',
                },
              },
              fault: {
                max: {
                  field: 'fault',
                },
              },
              rout_mnt: {
                max: {
                  field: 'rout_mnt',
                },
              },
              non_rout_mnt: {
                max: {
                  field: 'non_rout_mnt',
                },
              },
              rate_utility: {
                bucket_script: {
                  buckets_path: {
                    utility: 'utility',
                    breakdown: 'breakdown',
                    fault: 'fault',
                    rout_mnt: 'rout_mnt',
                    non_rout_mnt: 'non_rout_mnt',
                  },
                  script: {
                    source:
                      '(params.utility) / (params.utility + params.breakdown + params.fault + params.rout_mnt + params.non_rout_mnt)',
                  },
                },
              },
              rate_breakdown: {
                bucket_script: {
                  buckets_path: {
                    utility: 'utility',
                    breakdown: 'breakdown',
                    fault: 'fault',
                    rout_mnt: 'rout_mnt',
                    non_rout_mnt: 'non_rout_mnt',
                  },
                  script: {
                    source:
                      '(params.breakdown) / (params.utility + params.breakdown + params.fault + params.rout_mnt + params.non_rout_mnt)',
                  },
                },
              },
              rate_fault: {
                bucket_script: {
                  buckets_path: {
                    utility: 'utility',
                    breakdown: 'breakdown',
                    fault: 'fault',
                    rout_mnt: 'rout_mnt',
                    non_rout_mnt: 'non_rout_mnt',
                  },
                  script: {
                    source:
                      '(params.fault) / (params.utility + params.breakdown + params.fault + params.rout_mnt + params.non_rout_mnt)',
                  },
                },
              },
              rate_rout_mnt: {
                bucket_script: {
                  buckets_path: {
                    utility: 'utility',
                    breakdown: 'breakdown',
                    fault: 'fault',
                    rout_mnt: 'rout_mnt',
                    non_rout_mnt: 'non_rout_mnt',
                  },
                  script: {
                    source:
                      '(params.rout_mnt) / (params.utility + params.breakdown + params.fault + params.rout_mnt + params.non_rout_mnt)',
                  },
                },
              },
              rate_non_rout_mnt: {
                bucket_script: {
                  buckets_path: {
                    utility: 'utility',
                    breakdown: 'breakdown',
                    fault: 'fault',
                    rout_mnt: 'rout_mnt',
                    non_rout_mnt: 'non_rout_mnt',
                  },
                  script: {
                    source:
                      '(params.non_rout_mnt) / (params.utility + params.breakdown + params.fault + params.rout_mnt + params.non_rout_mnt)',
                  },
                },
              },
            },
          },
        },
      },
    },
  };

  return fetchElasticQuery(index, query);
}

export async function fetchCalsysAlarmEvents({
  assetId,
  index,
  start,
  end,
  sort,
  pagination,
  filter,
}) {
  const { startMoment, endMoment } = parseDateRange(start, end);
  const query = {
    ...pagination,
    sort,
    _source: {
      includes: ['name', 'description', 'timestamp'],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'alarm_events',
            },
          },
          ...(filter ? includeFilters(filter) : []),
        ],
        filter: [
          {
            range: {
              timestamp: {
                gte: startMoment.format().toString(),
                lte: endMoment.format().toString(),
              },
            },
          },
        ],
      },
    },
  };

  return fetchElasticQuery(index, query);
}

export async function fetchCalsysAlarmEventsCategories({
  assetId,
  index,
  start,
  end,
}) {
  const { startMoment, endMoment } = parseDateRange(start, end);
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'alarm_events',
            },
          },
        ],
        filter: [
          {
            range: {
              timestamp: {
                gte: startMoment.format().toString(),
                lte: endMoment.format().toString(),
              },
            },
          },
        ],
      },
    },
    aggs: {
      name: {
        terms: {
          field: 'name.keyword',
          size: 100,
          order: {
            _count: 'desc',
          },
        },
      },
      description: {
        terms: {
          field: 'description.keyword',
          size: 100,
          order: {
            _count: 'desc',
          },
        },
      },
    },
  };

  return fetchElasticQuery(index, query);
}

export async function fetchCalsysValidationEvents({
  assetId,
  index,
  start,
  end,
  filter,
  sort,
  pagination,
}) {
  const { startMoment, endMoment } = parseDateRange(start, end);
  const query = {
    sort,
    ...pagination,
    _source: {
      includes: [
        'id',
        'date_and_time',
        'result',
        'name',
        'initiaded_by',
        'validation_method',
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'validation_events',
            },
          },
          ...(filter ? includeFilters(filter) : []),
        ],
        filter: [
          {
            range: {
              date_and_time: {
                gte: startMoment.format().toString().slice(0, -6),
                lte: endMoment.format().toString().slice(0, -6),
              },
            },
          },
        ],
      },
    },
  };

  return fetchElasticQuery(index, query);
}

export async function fetchCalsysValidationEventsCategories({
  assetId,
  index,
  start,
  end,
}) {
  const { startMoment, endMoment } = parseDateRange(start, end);
  const query = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'mss.id': assetId,
            },
          },
          {
            match: {
              'mss.collector_key': 'validation_events',
            },
          },
        ],
        filter: [
          {
            range: {
              date_and_time: {
                gte: startMoment.format().toString().slice(0, -6),
                lte: endMoment.format().toString().slice(0, -6),
              },
            },
          },
        ],
      },
    },
    aggs: {
      name: {
        terms: {
          field: 'name.keyword',
          size: 100,
          order: {
            _count: 'desc',
          },
        },
      },
      result: {
        terms: {
          field: 'result.keyword',
          size: 100,
          order: {
            _count: 'desc',
          },
        },
      },
      initiaded_by: {
        terms: {
          field: 'initiaded_by.keyword',
          size: 100,
          order: {
            _count: 'desc',
          },
        },
      },
      method: {
        terms: {
          field: 'validation_method.keyword',
          size: 100,
          order: {
            _count: 'desc',
          },
        },
      },
    },
  };

  return fetchElasticQuery(index, query);
}

export async function fetchAnalyzerManagementApplication(
  assetId: string,
): Promise<any> {
  return Api.get(`/assets/${assetId}/`);
}

export async function fetchAnalyzerManagementApplications(parameters: {
  site: string;
}): Promise<any> {
  parameters['type'] = 'analyzer_management';
  parameters['deleted'] = 'false';
  return Api.get(`/assets/`, { params: parameters });
}

export async function fetchAnalyzerManagementApplicationsById(): Promise<any> {
  return Api.get(
    `/assets/autocomplete/name/?model_name=AnalyzerManagementApplicationAAIMS`,
  );
}
