import React from 'react';
import { Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { NozomiHealthLog } from '../../../types';

export interface Props {
  log: NozomiHealthLog;
  closeModal: () => void;
}

function HealthLogModal({ log, closeModal }: Props) {
  const [t] = useTranslation();
  return (
    <Modal isOpen toggle={closeModal} size="lg">
      <ModalHeader toggle={closeModal} cssModule={{ 'modal-title': 'w-100' }}>
        {`${t('Health Log')}: ${log.id} @ ${moment(log.time).format('LLLL')}`}
      </ModalHeader>
      <ModalBody>
        <div className="border p-3">
          <div>
            <div className="form-group row mt-4">
              <label
                htmlFor="id"
                className="col-sm-2 col-form-label font-weight-bold"
              >
                {t('ID')}
              </label>
              <div className="col-sm-4">
                <input
                  type="text"
                  readOnly
                  className="form-control-plaintext"
                  id="typidename"
                  value={log.id}
                />
              </div>
              <label
                htmlFor="dateAndTime"
                className="col-sm-2 col-form-label font-weight-bold"
              >
                {t('Date & Time')}
              </label>
              <div className="col-sm-4">
                <input
                  type="text"
                  readOnly
                  className="form-control-plaintext"
                  id="dateAndTime"
                  value={moment(log.time).format('LLLL')}
                />
              </div>
            </div>
            <div className="form-group row mt-4">
              <label
                htmlFor="synchronized"
                className="col-sm-2 col-form-label font-weight-bold"
              >
                {t('Synchronized')}
              </label>
              <div className="col-sm-4">
                <input
                  type="text"
                  readOnly
                  className="form-control-plaintext"
                  id="synchronized"
                  value={(log.synchronized ? t('Yes') : t('No')).toString()}
                />
              </div>
              <label
                htmlFor="appliance_host"
                className="col-sm-2 col-form-label font-weight-bold"
              >
                {t('Appliance Host')}
              </label>
              <div className="col-sm-4">
                <input
                  type="text"
                  readOnly
                  className="form-control-plaintext"
                  id="appliance_host"
                  value={log.appliance_host}
                />
              </div>
            </div>
            <div className="form-group row mt-4">
              <label
                htmlFor="appliance_id"
                className="col-sm-2 col-form-label font-weight-bold"
              >
                {t('Appliance ID')}
              </label>
              <div className="col-sm-4">
                <input
                  type="text"
                  readOnly
                  className="form-control-plaintext"
                  id="appliance_id"
                  value={log.appliance_id}
                />
              </div>
              <label
                htmlFor="appliance_name"
                className="col-sm-2 col-form-label font-weight-bold"
              >
                {t('Appliance IP')}
              </label>
              <div className="col-sm-4">
                <input
                  type="text"
                  readOnly
                  className="form-control-plaintext"
                  id="appliance_id"
                  value={log.appliance_ip}
                />
              </div>
            </div>
            <div className="form-group row mt-5">
              <label
                htmlFor="description"
                className="col-sm-2 col-form-label font-weight-bold"
              >
                {t('Description')}
              </label>
              <div className="col-sm-12">
                <Input
                  type="textarea"
                  name="text"
                  id="description"
                  disabled
                  value={log.info?.description}
                  className="text-black"
                  rows="10"
                />
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default HealthLogModal;
