import React from 'react';
import { Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { NozomiAlarm } from '../../../types';

export interface Props {
  alarm: NozomiAlarm;
  closeModal: () => void;
}

function AlarmDetailModal({ alarm, closeModal }: Props) {
  const [t] = useTranslation();
  return (
    <Modal isOpen toggle={closeModal} size="lg">
      <ModalHeader toggle={closeModal} cssModule={{ 'modal-title': 'w-100' }}>
        {`${t('Alarm')}: ${alarm.type_name} @ ${moment(
          alarm.created_time,
        ).format('LLLL')}`}
      </ModalHeader>
      <ModalBody>
        <div className="border p-3">
          <div>
            <div className="form-group row mt-4">
              <label
                htmlFor="typename"
                className="col-sm-2 col-form-label font-weight-bold"
              >
                {t('Type')}
              </label>
              <div className="col-sm-4">
                <input
                  type="text"
                  readOnly
                  className="form-control-plaintext"
                  id="typename"
                  value={alarm.type_name}
                />
              </div>
              <label
                htmlFor="dateAndTime"
                className="col-sm-2 col-form-label font-weight-bold"
              >
                {t('Date & Time')}
              </label>
              <div className="col-sm-4">
                <input
                  type="text"
                  readOnly
                  className="form-control-plaintext"
                  id="dateAndTime"
                  value={moment(alarm.created_time).format('LLLL')}
                />
              </div>
            </div>
            <div className="form-group row mt-4">
              <label
                htmlFor="status"
                className="col-sm-2 col-form-label font-weight-bold"
              >
                {t('Status')}
              </label>
              <div className="col-sm-4">
                <input
                  type="text"
                  readOnly
                  className="form-control-plaintext"
                  id="status"
                  value={alarm.status}
                />
              </div>
              <label
                htmlFor="protocol"
                className="col-sm-2 col-form-label font-weight-bold"
              >
                {t('Protocol')}
              </label>
              <div className="col-sm-4">
                <input
                  type="text"
                  readOnly
                  className="form-control-plaintext"
                  id="protocol"
                  value={alarm.protocol}
                />
              </div>
            </div>
            <div className="form-group row mt-4">
              <label
                htmlFor="ip_src"
                className="col-sm-2 col-form-label font-weight-bold"
              >
                {t('Source IP Address')}
              </label>
              <div className="col-sm-4">
                <input
                  type="text"
                  readOnly
                  className="form-control-plaintext"
                  id="ip_src"
                  value={alarm.ip_src}
                />
              </div>
              <label
                htmlFor="port_src"
                className="col-sm-2 col-form-label font-weight-bold"
              >
                {t('Source IP Port')}
              </label>
              <div className="col-sm-4">
                <input
                  type="text"
                  readOnly
                  className="form-control-plaintext"
                  id="port_src"
                  value={alarm.port_src}
                />
              </div>
            </div>
            <div className="form-group row mt-4">
              <label
                htmlFor="ip_dst"
                className="col-sm-2 col-form-label font-weight-bold"
              >
                {t('Destination IP Address')}
              </label>
              <div className="col-sm-4">
                <input
                  type="text"
                  readOnly
                  className="form-control-plaintext"
                  id="ip_dst"
                  value={alarm.ip_dst}
                />
              </div>
              <label
                htmlFor="port_dst"
                className="col-sm-2 col-form-label font-weight-bold"
              >
                {t('Destination IP Port')}
              </label>
              <div className="col-sm-4">
                <input
                  type="text"
                  readOnly
                  className="form-control-plaintext"
                  id="port_dst"
                  value={alarm.port_dst}
                />
              </div>
            </div>
            <div className="form-group row mt-4">
              <label
                htmlFor="risk"
                className="col-sm-2 col-form-label font-weight-bold"
              >
                {t('Risk')}
              </label>
              <div className="col-sm-4">
                <input
                  type="text"
                  readOnly
                  className="form-control-plaintext"
                  id="risk"
                  value={alarm.risk}
                />
              </div>
            </div>
            <div className="form-group row mt-5">
              <label
                htmlFor="description"
                className="col-sm-2 col-form-label font-weight-bold"
              >
                {t('Description')}
              </label>
              <div className="col-sm-12">
                <Input
                  type="textarea"
                  name="text"
                  id="description"
                  disabled
                  value={alarm.description}
                  className="text-black"
                  rows="10"
                />
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default AlarmDetailModal;
