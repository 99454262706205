import { fetchElasticQuery } from 'common/services/FetchElasticQuery';
import { parseDateRange } from 'common/utils';

export async function fetchSyslogFilters({ syslogName, index, start, end }) {
  const { startMoment, endMoment } = parseDateRange(start, end);

  const query = {
    aggs: {
      facility_label: {
        terms: {
          field: 'facility_label.keyword',
          size: 20,
          order: {
            _key: 'asc',
          },
        },
      },
      severity_label: {
        terms: {
          field: 'severity_label.keyword',
          size: 20,
          order: {
            _key: 'asc',
          },
        },
      },
      program: {
        terms: {
          field: 'program.keyword',
          size: 150,
          order: {
            _key: 'asc',
          },
        },
      },
    },
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              'logsource.keyword': syslogName,
            },
          },
        ],
        filter: {
          range: {
            '@timestamp': {
              gte: startMoment.format().toString(),
              lte: endMoment.format().toString(),
            },
          },
        },
      },
    },
  };
  return fetchElasticQuery(index, query);
}

export async function fetchSyslogDetail({ id, index }) {
  /**
   *  get syslog detail
   */
  const query = {
    query: {
      bool: {
        must: [
          // {
          //   match: {
          //     'logsource.keyword': syslogName,
          //   },
          // },
          {
            match: {
              _id: id,
            },
          },
        ],
      },
    },
  };
  return fetchElasticQuery(index, query);
}
const initialSort = {
  '@timestamp': 'desc',
};

export async function fetchSyslogData({
  syslogHost,
  index,
  filter,
  start,
  end,
  sort = initialSort,
  pagination,
}) {
  /**
   *  get syslog data
   */
  const { startMoment, endMoment } = parseDateRange(start, end);

  const query: any = {
    ...pagination,
    sort,
    _source: {
      includes: [
        '@timestamp',
        'facility_label',
        'message',
        'program',
        'severity_label',
        'logsource',
      ],
    },
    query: {
      bool: {
        must: [
          {
            match: {
              'logsource.keyword': syslogHost,
            },
          },
        ],
        filter: {
          range: {
            '@timestamp': {
              gte: `${startMoment.format()}`,
              lte: `${endMoment.format()}`,
            },
          },
        },
      },
    },
  };

  function boolShouldMatchPhrase(key, values) {
    const arr = values.map(x => ({
      match_phrase: {
        [key]: x,
      },
    }));

    return {
      bool: {
        should: arr,
      },
    };
  }

  if (filter) {
    const filters = Object.keys(filter);
    const activatedFilters = filters
      .filter(x => filter[x] != null)
      .map(x => boolShouldMatchPhrase(x, filter[x]));
    activatedFilters.forEach(x => query.query.bool.must.push(x));
  }

  return fetchElasticQuery(index, query);
}
