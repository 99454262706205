import Route from 'common/components/Router/Route';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from 'common/components/Layout/Layout';
import {
  fetchNavRequested,
  fetchTimezonesRequested,
  fetchLanguagesRequested,
} from 'settings/store/slice';
import { fetchDashboardsRequested } from 'dashboards/store/slice';
import { selectMe } from 'settings/store/selectors';
import TermsConditions from 'termsConditions/views/TermsConditions';
import { Switch } from 'react-router-dom';
import CenterRoutes from './CenterRoutes';
import SiteRoutes from './SiteRoutes';
import AutoRouting from './AutoRouting';
import NotFound from 'common/views/Exceptions/NotFound';
import initI18n from '../i18n';
import Loading from '../Loading';
import { RemoteClient } from '@mss/mss-component-library';
import { selectedSite } from 'sites/store/selectors';

const I18n = ({ user, children }: any) => {
  initI18n(user.language || 'en');

  return <React.Suspense fallback={<Loading />}>{children}</React.Suspense>;
};

const MainRoutes = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectMe);
  const site = useSelector(selectedSite);

  useEffect(() => {
    dispatch(fetchNavRequested());
    dispatch(fetchLanguagesRequested());
    dispatch(fetchTimezonesRequested());
    dispatch(fetchTimezonesRequested());
    dispatch(fetchDashboardsRequested());
  }, [dispatch, user.show_terms]);
  return (
    <>
      {user.show_terms && (
        <Layout layout="Empty">
          <TermsConditions />
        </Layout>
      )}
      {!user.show_terms && (
        <I18n user={user}>
          <Switch>
            <Route path={['/']} exact component={AutoRouting} />
            <Route
              path={['/site/:siteId/remote-access/client/:sessionId']}
              render={() => (
                <RemoteClient
                  component="center"
                  port={process.env.REACT_APP_REMOTE_PORT}
                  siteId={site.id}
                />
              )}
            />
            <Route path={['/site/:siteId']} component={SiteRoutes} />
            <Route
              path={[
                '/sites',
                '/settings',
                '/config',
                '/release-notes',
                '/authorization',
              ]}
              component={CenterRoutes}
            />
            <Route render={() => <NotFound fullscreen />} />
          </Switch>
        </I18n>
      )}
    </>
  );
};

export default MainRoutes;
