import { TFunction } from 'i18next';

export const getReportNotificationStrings = (
  type: string,
  t: TFunction,
): { successString: string; failString: string } => {
  switch (type) {
    case 'generate':
      return {
        successString: t(
          'The report has been sent for generation successfully',
        ),
        failString: t('Report generation has been failed'),
      };
    case 'publish':
      return {
        successString: t('The report has been published successfully'),
        failString: t('Report publishing has been failed'),
      };
    case 'unpublish':
      return {
        successString: t('The report has been unpublished successfully'),
        failString: t('Report unpublishing has been failed'),
      };
    case 'delete':
      return {
        successString: t('The report has been deleted successfully'),
        failString: t('Report deletion has been failed'),
      };
    default:
      return {
        successString: '',
        failString: '',
      };
  }
};

export const getReportStateTitle = (state, t: TFunction) => {
  const states = {
    locked: t('Locked'),
    draft: t('Draft'),
    error: t('Error'),
    generated: t('Generated'),
    generating: t('Generating'),
    published: t('Published'),
  };
  return states[state] || t('Unknown');
};
