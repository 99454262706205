import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMeRequested } from 'settings/store/slice';
import { selectMe } from 'settings/store/selectors';
import { AccessDenied } from '@mss/mss-component-library';
import { AppContext } from '../../../App';

export default function UserGuard({ children }) {
  const user = useSelector(selectMe);
  const dispatch = useDispatch();
  const { keycloak } = useContext(AppContext);
  useEffect(() => {
    if (!user.id) dispatch(fetchMeRequested());
  }, [dispatch, user.id]);
  if (user.is_active === false)
    return <AccessDenied variant="center" keycloak={keycloak} />;
  return user.id ? <>{children}</> : null;
}
