import { createSlice, createAction } from '@reduxjs/toolkit';
import { arrayToObject } from 'common/utils';
import { QueryParams } from '@mss/mss-component-library';

// List field asset
export const fetchFieldAssetsRequested = createAction<QueryParams>(
  'fieldAssets/fetchFieldAssetsRequested',
);
export const fetchFieldAssetFiltersRequested = createAction<QueryParams>(
  'fieldAssets/fetchFieldAssetFiltersRequested',
);
export const fetchFieldAssetsFailed = createAction(
  'fieldAssets/fetchFieldAssetsFailed',
);
export const fetchFieldAssetFiltersFailed = createAction(
  'fieldAssets/fetchFieldAssetsFailed',
);

// Single field asset
export const fetchFieldAssetRequested = createAction<{
  assetId: string;
}>('fieldAssets/fetchFieldAssetRequested');
export const fetchFieldAssetFailed = createAction(
  'fieldAssets/fetchFieldAssetFailed',
);

const modalsSlice = createSlice({
  name: 'fieldAssets',
  initialState: {
    fieldAssets: [],
    fieldAssetFilters: {},
    fieldAssetsById: {},
    fieldAsset: {},
    total: 0,
  },
  reducers: {
    fetchFieldAssetsCompleted(state, action) {
      state.fieldAssets = action.payload.results || [];
      state.fieldAssetsById = arrayToObject(state.fieldAssets, 'id');
      state.total = action.payload.count;
    },
    fetchFieldAssetFiltersCompleted(state, action) {
      state.fieldAssetFilters = action.payload || {};
    },
    fetchFieldAssetCompleted(state, action) {
      state.fieldAsset = action.payload;
    },
  },
});

export const {
  fetchFieldAssetCompleted,
  fetchFieldAssetFiltersCompleted,
  fetchFieldAssetsCompleted,
} = modalsSlice.actions;

export default modalsSlice.reducer;
