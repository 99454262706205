import React from 'react';
import { useSelector } from 'react-redux';
import { initials } from 'common/utils';
import { selectMe } from 'settings/store/selectors';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Api from '../../../authorization/services/Api';

const UserHeader: React.FC<{}> = () => {
  const [t] = useTranslation('common');
  const user = useSelector(selectMe);
  return (
    <div className="d-flex align-items-center ">
      <div className="avatar">
        <span className="initials">{initials(user.display_name)}</span>
      </div>
      <UncontrolledDropdown>
        <DropdownToggle tag="a">
          <span className="ml-2 d-none d-lg-block pr-0 leading-none cursor-pointer">
            <span className="text-white">{user.display_name}</span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-arrow" right>
          <DropdownItem tag={Link} to="/settings">
            {t('Settings')}
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={() => Api.user.logout()}>
            {t('Logout')}
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};

export default UserHeader;
