export type MainType = 'assets' | 'applications';
export type AssetType =
  | 'compute'
  | 'field'
  | 'plc'
  | 'plc-fcn'
  | 'network'
  | 'env'
  | 'wsus'
  | 'mcafee_epo'
  | 'prm'
  | 'epo'
  | 'control'
  | 'aaims'
  | 'calsys'
  | 'veeam'
  | 'claroty'
  | 'isae'
  | 'nozomi';
export type Vendor = 'yokogawa' | 'emerson' | '*';
export type SubType = 'ab' | 'al' | 'odu' | '*';
export type EndPoint =
  | 'catalogue'
  | 'logs'
  | 'metric'
  | 'metrics'
  | 'alarms'
  | 'syslog'
  | 'centum_vp-catalogue'
  | 'centum_vp-logs'
  | 'heartbeat'
  | 'backup_sessions'
  | 'restore_sessions'
  | 'replica_sessions'
  | 'backup_servers';

interface AssetIndexParams {
  siteId: string;
  type?: AssetType;
  mainType?: MainType;
  vendor?: Vendor;
  subType?: SubType;
  endpoint: EndPoint;
  wildCard?: boolean;
}
export default function assetIndex({
  siteId,
  mainType = 'assets',
  type,
  vendor,
  subType,
  endpoint,
  wildCard = true,
}: AssetIndexParams): string {
  let input: any[] = [siteId];
  if (vendor) input.push(vendor);
  input.push(mainType);
  if (type) input.push(type);
  if (subType) input.push(subType);
  input.push(endpoint);

  return `${input.join('-')}${wildCard ? '*' : ''}`;
}
