import Api from 'common/services/Api';
import { ComputeAsset } from '@mss/mss-component-library';

export async function fetchComputeAssets(parameters: {
  site: string;
}): Promise<ComputeAsset[]> {
  parameters['type'] = 'compute';
  parameters['deleted'] = 'false';
  return Api.get(`/assets/`, { params: parameters });
}

export async function fetchComputeAsset({ id, ...params }) {
  return Api.get(`/assets/${id}/`, { params });
}
