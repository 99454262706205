import { SortState } from '../types';
import { antdToEsSort } from '@mss/mss-component-library';

export type FilterState<DataKey extends string> = {
  [key in DataKey]: string[];
};

export type CategoryFilters<DataKey extends string> = {
  [key in DataKey]: { buckets: { key: string }[] };
};

type FilterMatchPhrase = {
  match_phrase: {
    [key: string]: string;
  };
};

type Filter = {
  bool: {
    should: FilterMatchPhrase[];
  };
};

export type ElasticFilterQuery = {
  id: string;
  index: string;
  filter: { [key: string]: string[] };
  start: string;
  end: string;
  sort?: Record<string, string>;
  pagination?: { from: number; size: number };
};

function boolShouldMatchPhrase(key: string, values: string[]): Filter {
  const arr = values.map(x => ({
    match_phrase: {
      [key]: x,
    },
  }));

  return {
    bool: {
      should: arr,
    },
  };
}
export function includeFilters(filter: FilterState<string>) {
  return Object.keys(filter)
    .filter(x => filter[x] != null)
    .map(x => boolShouldMatchPhrase(x, filter[x]));
}

export const elasticSorting = (keywords: string[], sorting: SortState) => {
  return {
    [keywords.includes(sorting.columnKey)
      ? `${sorting.columnKey}.keyword`
      : sorting.columnKey]: antdToEsSort(sorting.order),
  };
};
