import { all, takeLatest, call, put } from 'redux-saga/effects';
import { fetchCenter, fetchCenterTerms } from '../services/CenterService';
import {
  fetchCenterCompleted,
  fetchCenterRequested,
  fetchCenterFailed,
  fetchCenterTermsCompleted,
  fetchCenterTermsRequested,
  fetchCenterTermsFailed,
} from './slice';

function* fetchCenterEffectSaga() {
  try {
    let { data } = yield call(fetchCenter);
    yield put(fetchCenterCompleted(data));
  } catch (e) {
    yield put(fetchCenterFailed());
  }
}

function* fetchCenterTermsEffectSaga() {
  try {
    let { data } = yield call(fetchCenterTerms);
    yield put(fetchCenterTermsCompleted(data));
  } catch (e) {
    yield put(fetchCenterTermsFailed());
  }
}

export function* fetchCenterWatcherSaga() {
  yield takeLatest(fetchCenterRequested.toString(), fetchCenterEffectSaga);
}

export function* fetchCenterTermsWatcherSaga() {
  yield takeLatest(
    fetchCenterTermsRequested.toString(),
    fetchCenterTermsEffectSaga,
  );
}

export default function* centerRootSaga() {
  yield all([fetchCenterWatcherSaga(), fetchCenterTermsWatcherSaga()]);
}
