import React from 'react';
import { NavLink } from 'react-router-dom';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import FieldAssetBreadcrumb from './FieldAssetBreadcrumb';
import ComputeAssetBreadcrumb from './ComputeAssetBreadcrumb';
import IIoTBreadcrumb from './IIoTBreadcrumb';
import SiteBreadcrumb from './SiteBreadcrumb';
import PlcDcsAssetBreadcrumb from './PlcDcsAssetBreadcrumb';
import NetworkAssetBreadcrumb from './NetworkAssetBreadcrumb';
import EnvAssetBreadcrumb from './EnvAssetBreadcrumb';
import { Trans } from 'react-i18next';
import SecurityApplicationBreadcrumb from './SecurityApplicationBreadcrumb';
import AssetManagementAppsBreadcrumb from './AssetManagementAppsBreadcrumb';
import AnalyzerManagementAppsBreadcrumb from './AnalyzerManagementAppsBreadcrumb';
import ControlAppsBreadcrumb from './ControlAppsBreadcrumb';
import DashboardBreadcrumb from 'dashboards/components/DashboardBreadcrumb';
import ReportsCategoryBreadcrumb from './ReportsCategoryBreadcrumb';
import ReportsSubcategoryBreadcrumb from './ReportsSubcategoryBreadcrumb';
import ReportsOverviewBreadcrumb from './ReportsOverviewBreadcrumb';

const breadcrumb = () => <></>;
const routes = [
  {
    path: '/site/:siteId/dashboard',
    breadcrumb: () => <Trans ns="common">Dashboards</Trans>,
  },
  {
    path: '/site/:siteId/dashboard/:slug',
    breadcrumb: DashboardBreadcrumb,
  },
  {
    path: '/site/:siteId/dashboard/compute',
    breadcrumb: () => <Trans ns="common">Compute assets</Trans>,
  },
  {
    path: '/site/:siteId/dashboard/field',
    breadcrumb: () => <Trans ns="common">Field assets</Trans>,
  },
  {
    path: '/site/:siteId/dashboard/plcdcs',
    breadcrumb: () => <Trans ns="common">PLC/DCS assets</Trans>,
  },
  {
    path: '/site/:siteId/dashboard/network',
    breadcrumb: () => <Trans ns="common">Network assets</Trans>,
  },
  {
    path: '/site/:siteId/dashboard/env',
    breadcrumb: () => <Trans ns="common">Environmental assets</Trans>,
  },
  {
    path: '/site/:siteId/dashboard/security',
    breadcrumb: () => <Trans ns="common">Security applications</Trans>,
  },
  {
    path: '/site/:siteId/applications/security',
    breadcrumb: () => <Trans ns="common">Security applications</Trans>,
  },
  {
    path: '/site/:siteId/applications/security/wsus/:securityAppid',
    breadcrumb: SecurityApplicationBreadcrumb,
  },
  {
    path: '/site/:siteId/applications/security/mcafee_epo/:securityAppid',
    breadcrumb: SecurityApplicationBreadcrumb,
  },
  {
    path: '/site/:siteId/applications/security/veeam/:securityAppid',
    breadcrumb: SecurityApplicationBreadcrumb,
  },
  {
    path: '/site/:siteId/applications/security/claroty/:securityAppid',
    breadcrumb: SecurityApplicationBreadcrumb,
  },
  {
    path: '/site/:siteId/applications/security/nozomi/:securityAppid',
    breadcrumb: SecurityApplicationBreadcrumb,
  },
  {
    path: '/site/:siteId/applications/asset_management',
    breadcrumb: () => <Trans ns="common">Asset Management Applications</Trans>,
  },
  {
    path: '/site/:siteId/applications/asset_management/:asset_managementId',
    breadcrumb: AssetManagementAppsBreadcrumb,
  },
  {
    path: '/site/:siteId/applications/analyzer_management',
    breadcrumb: () => (
      <Trans ns="common">Analyzer Management Applications</Trans>
    ),
  },
  {
    path: '/site/:siteId/applications/analyzer_management/:analyzer_managementId',
    breadcrumb: AnalyzerManagementAppsBreadcrumb,
  },
  {
    path: '/site/:siteId/applications/control',
    breadcrumb: () => <Trans ns="controlApps">Control Applications</Trans>,
  },
  {
    path: '/site/:siteId/applications/control/:controlId',
    breadcrumb: ControlAppsBreadcrumb,
  },
  {
    path: '/site/:siteId/assets/compute/:computeAssetId',
    breadcrumb: ComputeAssetBreadcrumb,
  },
  {
    path: '/site/:siteId/assets/compute',
    breadcrumb: () => <Trans ns="common">Compute assets</Trans>,
  },
  {
    path: '/site/:siteId/assets/field/:assetId',
    breadcrumb: FieldAssetBreadcrumb,
  },
  {
    path: '/site/:siteId/assets/field',
    breadcrumb: () => <Trans ns="common">Field assets</Trans>,
  },

  {
    path: '/site/:siteId/assets/iiot/:iioTAssetId',
    breadcrumb: IIoTBreadcrumb,
  },
  {
    path: '/site/:siteId/assets/iiot',
    breadcrumb: () => <Trans ns="common">IIoT assets</Trans>,
  },
  {
    path: '/site/:siteId/assets/plc-dcs',
    breadcrumb: () => <Trans ns="common">PLC/DCS assets</Trans>,
  },
  {
    path: '/site/:siteId/assets/plc-dcs/:plcDcsAssetId',
    breadcrumb: PlcDcsAssetBreadcrumb,
  },
  {
    path: '/site/:siteId/assets/network',
    breadcrumb: () => <Trans ns="common">Network assets</Trans>,
  },
  {
    path: '/site/:siteId/assets/network/:networkAssetId',
    breadcrumb: NetworkAssetBreadcrumb,
  },
  {
    path: '/site/:siteId/assets/env',
    breadcrumb: () => <Trans ns="common">Environmental assets</Trans>,
  },
  {
    path: '/site/:siteId/assets/env/:envAssetId',
    breadcrumb: EnvAssetBreadcrumb,
  },
  {
    path: '/site/:siteId/remote-access',
    breadcrumb: () => <Trans ns="common">Remote access</Trans>,
  },
  {
    path: '/site/:siteId/alerts',
    breadcrumb: () => <Trans ns="common">Alerts</Trans>,
  },
  {
    path: '/site/:siteId/reports',
    breadcrumb: () => <Trans ns="common">APR Reports</Trans>,
  },
  {
    path: '/site/:siteId/reports/:reportId',
    breadcrumb: ReportsOverviewBreadcrumb,
  },
  {
    path: '/site/:siteId/reports/:reportId/alarm-resolution/category/:categoryId',
    breadcrumb: ReportsCategoryBreadcrumb,
  },
  {
    path: '/site/:siteId/reports/:reportId/alarm-resolution/category/:categoryId/subcategory/:subcategoryId/',
    breadcrumb: ReportsSubcategoryBreadcrumb,
  },
  {
    path: '/site/:siteId/file-transfer',
    breadcrumb: () => <Trans ns="common">File Transfer</Trans>,
  },
  {
    path: '/settings/user',
    breadcrumb: () => <Trans ns="common">Settings</Trans>,
  },
  {
    path: '/release-notes',
    breadcrumb: () => <Trans ns="common">Release notes</Trans>,
  },
  {
    path: '/config',
    breadcrumb: () => <Trans ns="common">Configuration</Trans>,
  },
  {
    path: '/sites/manager',
    breadcrumb: () => <Trans ns="common">Site Manager</Trans>,
  },
  {
    path: '/sites/manager/new',
    breadcrumb: () => <Trans ns="sites">New site</Trans>,
  },
  {
    path: '/config/license',
    breadcrumb: () => <Trans ns="common">Licenses</Trans>,
  },
  {
    path: '/config/license/new',
    breadcrumb: () => <Trans ns="common">New license</Trans>,
  },
  {
    path: '/config/license/:licenseId',
    breadcrumb: () => <Trans ns="common">Update license</Trans>,
  },
  {
    path: '/authorization/users/',
    breadcrumb: () => <Trans ns="common">Users</Trans>,
  },
  {
    path: '/authorization/users/:userId',
    breadcrumb,
  },
  {
    path: '/authorization/groups/',
    breadcrumb: () => <Trans ns="common">Groups</Trans>,
  },
  {
    path: '/authorization/groups/:groupId',
    breadcrumb,
  },
  { path: '/sites/manager/:siteId', breadcrumb: SiteBreadcrumb },
  {
    path: '/sites/manager/:siteId/license',
    breadcrumb: () => <Trans ns="common">Licenses</Trans>,
  },
  {
    path: '/sites/manager/:siteId/license/new',
    breadcrumb: () => <Trans ns="common">Add</Trans>,
  },
  {
    path: '/sites/manager/:siteId/license/:licenseId',
    breadcrumb: () => <Trans ns="common">Edit</Trans>,
  },
  {
    path: '/sites/manager/:siteId/open-search-inspect',
    breadcrumb: () => <Trans ns="common">Open Search — Inspect</Trans>,
  },
  {
    path: '/sites/manager/:siteId/open-search-migrate',
    breadcrumb: () => <Trans ns="common">Open Search — Migrate</Trans>,
  },
];

const options = {
  disableDefaults: true,
};

function Breadcrumbs({
  breadcrumbs,
  label,
}: any & { label?: string | React.ReactNode }) {
  return (
    <ol className="breadcrumb" aria-label="breadcrumbs">
      {breadcrumbs.map(({ match, breadcrumb }, index) => {
        const lastBreadcrum = breadcrumbs[breadcrumbs.length - 1];
        const isLast = breadcrumb === lastBreadcrum.breadcrumb;
        return (
          // other props are available during render, such as `location`
          // and any props found in your route objects will be passed through too

          <li
            key={index}
            className={`breadcrumb-item ${isLast ? 'active' : ''}`}
          >
            <NavLink to={match.url}>
              {breadcrumb}
              {isLast && label}
            </NavLink>
          </li>
        );
      })}
    </ol>
  );
}

export default withBreadcrumbs(routes, options)(Breadcrumbs);
