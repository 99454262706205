import './polyfills';
import 'react-app-polyfill/ie11';
import { keycloakEntry } from '@mss/mss-component-library';
import App from './App';
import * as serviceWorker from './serviceWorker';

if (
  process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_DISABLE_MSW !== 'true'
) {
  const { worker } = require('./mocks/browser');
  worker.start();
}

const tokens = {
  access: sessionStorage.getItem('token') || undefined,
  refresh: sessionStorage.getItem('refresh-token') || undefined,
};
const url = `${
  process.env.REACT_APP_API_URL || 'http://center.nginx/api'
}/auth/config/`;

keycloakEntry(url, tokens, App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
